import React from 'react';
import PageHead from '../components/PageHead';
import partnersImg from '../assets/partners.png';
import Partner from '../components/Partner';
import Aligned from '../assets/Aligned.png';
import Cmore from '../assets/cmore.webp';
import LineLink from '../assets/linsen-group.webp';
import JrPacking from '../assets/jrpacking.png';
import ROTECH from '../assets/ROTECH.png';
import cpi from '../assets/cpi.png';
import { Helmet } from 'react-helmet-async';
import { v4 as uuidv4 } from 'uuid';
const Partners = () => {
  const partners = [
    {
      title:'Aligned',
      img:Aligned,
      description:'Aligned Machinery was found in 2004, located in the international metropolis of Shanghai, with five subsidiaries and factories. It is a technology-based company integrating R&D, manufacture and marketing and related services of pharma machinery and packing machinery',
      link:'https://www.aligned.com.cn/',
      reverse:false
    },
    {
      title:'Line link',
      img:LineLink,
      description:'For cleanroom parts, we can offer cleanroom panels, doors, windows, Medium filters, HEPA filters, pass box, weighing booths(Sampling & Dispensing), Laminar air flows, Air showers, Stainless steel furniture, Cleanroom telephones, LED Lights, etc. For raw materials, we can offer Rockwool(Both plate or coil) using for the insulation of the exterior wall and petroleum pipe.',
      link:'https://www.linelink-linsen.com/',
      reverse:true
    },
    {
      title:'JR Packing',
      img:JrPacking,
      description:'Jrpacking provides premium semi & full automatic aerosol filling machine, bag on valve (BOV) aerosol filling machines & lab test equipment.',
      link:'https://jrpacking.com/',
      reverse:false
    },
    {
      title:'C More',
      img:Cmore,
      description:'Jrpacking provides premium semi & full automatic aerosol filling machine, bag on valve (BOV) aerosol filling machines & lab test equipment.',
      link:'https://www.cncaremore.com/china-gummy-candy-machine/',
      reverse:true
    },
    {
      title:'ROTECH',
      description:"Life technology, green future, unremitting efforts for environmental protection, providing customers with sustainable value",
      link:'http://www.rotech-group.com/',
      img:ROTECH,
      reverse:false
    },
    {
      title:'Aluminium Packaging Supplier',
      description:"",
      link:'https://bestcosmeticspackaging.com/',
      img:cpi,
      reverse:true
    },
  ]
  return (
    
    <div className='partners'>
      <Helmet>
        <title>Pharmachinery Partners</title>
        <meta name="description" content="Pharmachinery group provides integrated pharmaceutical and engineering solutions in Egypt and MENA region. Pharmachinery is your premier pharmaceutical machinery and Cleanroom solutions provider." />
      </Helmet>
      <PageHead img={partnersImg} />
      <div className="section-head-info flex flex-col items-center justify-center text-center p-3 py-8">
        <p className='text-red-600 font-bold py-1'>Our Partners</p>
        <h1 className='text-2xl	font-bold dark py-1'>Partners That Growing Success <br /> Together With Us </h1>
      </div>
     <div className='p-8'>
      {partners.map((partner) => {
        return <Partner key={uuidv4()} link={partner.link} description={partner.description} reverse={partner.reverse} title={partner.title} img={partner.img} />
      })}
     </div>
    </div>
  )
}

export default Partners;